import MedsysImg1 from "../../images/productDescription/Medsys-img-1.jpg" 
import MedsysImg2 from "../../images/productDescription/Medsys-img-2.jpg" 
import MedsysImg3 from "../../images/productDescription/Medsys-img-3.jpg" 
import MedsysImg4 from "../../images/productDescription/Medsys-img-4.jpg" 
import MedsysImg5 from "../../images/productDescription/Medsys-img-5.jpg" 
import MedisignImg1 from "../../images/productDescription/Medisign-img-1.png" 
import MedisignImg2 from "../../images/productDescription/Medisign-img-2.png" 

export const descriptionMedsys = [
  {
    title: "Agenda",
    color: "green",
    layout: "regular",
    image: MedsysImg1,
    items: [
      {
        title: "Multiples agendas",
        description: `Opción de controlar de manera eficiente múltiples agendas
        en la misma pantalla` 
      },
      {
        title: "Configuración de horarios",
        description: `Posibilidad de configurar horarios no disponibles por
        médico y días festivos o no laborales` 
      },
      {
        title: "Consulta de citas",
        description: `Opción para consultar las citas del mes y poder definir
        fácilmente los espacios disponibles al momento de a signar una cita`
      },
      {
        title:  "Agenda semanal",
        description: `Con esta opción se pueden revisar las citas de una semana
        en particular, facilitando la asignación de horario disponible` 
      },
    ]
  },
  {
    title: "Historia Clínica",
    color: "blue",
    layout: "regular",
    image: MedsysImg2,
    items: [
      {
        title: "Registro Digital de Historia Clínica",
        description: `El sistema cumple con las regulaciones estatales para la
        administración de datos clínicos y los estándares de seguridad de la
        información.` 
      },
      {
        title: "Impresión de Formatos",
        description: `Con MedSys® podrá generar reportes impresos para fórmulas
        médicas, incapacidades, resúmenes de historia clínica, recomendaciones,
        entre otros.` 
      },
      {
        title: "Corrector Ortográfico",
        description: `Incluye corrector ortográfico que puede utilizar al
        momento de ingresar datos a la historia clínica.` 
      },
      {
        title:  "Seguridad de ingreso por Usuario",
        description: `El sistema le permite restringir el acceso de ingreso y
        lectura a determinados usuarios.` 
      },
    ]
  },
  {
    title: "Imágenes Diagnósticas y Fotografías",
    color: "greenlight",
    layout: "regular",
    image: MedsysImg3,
    description: ` MedSys es la herramienta ideal para organizar las imágenes
    asociadas a la Historia Clínica de pacientes. Es una de las características
    más importantes y que dan gran potencial al sistema, puesto que se
    aprovechan las ventajas de la asociación de la información gráfica con los
    datos de historia clínica.` 
  },
  {
    title: "Estadísticas",
    color: "green",
    layout: "regular",
    image: MedsysImg4,
    items: [
      {
        title: "De Antecedentes Clínicos",
        description: `El sistema brinda la posibilidad de obtener un análisis
        estadístico gráfico de antecedentes por sexo y rangos de edad, muy útil
        para desarrollar estudios clínicos.`
      },
      {
        title: "Pacientes sin asistencia a consulta",
        description: `Esta información le permitirá hacer seguimiento a los
        pacientes que no han continuado con procedimientos o tratamientos
        médicos y mejorará el servicio al cliente de su institución.`
      },
      {
        title: "Captación de Pacientes",
        description: ` El sistema le suministra indicadores de la inclusión de
        nuevos pacientes y le permitirá mejorar las estrategias para aumentar
        el volumen de pacientes.`
      },
    ]
  },
  {
    title: "Generación de RIPS e informes para el Ministerio de Salud",
    color: "blue",
    layout: "regular",
    image: MedsysImg5,
    description: `Con este sistema de información se facilitan las labores de
    suministro de datos al Ministerio de Salud, puesto que se pueden generar
    los RIPS (Registros individuales de prestación de servicios) de manera
    sencilla y eficiente.`,
  },
]

export const descriptionMedisign = [
  {
    title: "Seguridad",
    color: "purple",
    layout: "regular",
    image: MedisignImg1,
    items: [
      {
        title: "Servidor de Aplicaciones",
        description: `Las imágenes de las firmas cumplen el único propósito de
        integrarse al consentimiento.Todas las imágenes siempre se eliminan del
        sistema donde se encuentre alojado el Servidor de Aplicaciones.`
      },
      {
        title: "Servidor en la nube",
        description: `Adicional al consentimiento creado, se realizan 2 copias
        del mismocon el propósito de garantizar la validez legal del
        consentimiento de acuerdo a lo estipulado en el numeral 4 de la Ley
        527, artículo 28 de 1999. La primera copia es enviada al paciente a
        través de correo electrónico con el documento adjunto; la segunda copia
        es almacenada en un servidor en la nube, propiedad de la empresa MedSys
        SAS.`
      },
      {
        title: "Servicio de alojamiento",
        description: `Si la persona propietaria del software tiene vínculo con
        un servicio de alojamiento de archivos (Dropbox, Google Drive,
          Onedrive, iCloud) la ruta destino de los documentos creados puede
        ubicarse en una carpeta dentro de este servicio. Los consentimientos
        creados se sincronizarán automáticamente y el propietario podrá acceder
        desde cualquier dispositivo y lugar a su información.`
      },
      {
        title: "Restauración de datos",
        description: `En caso de pérdida completa y absoluta de los
        consentimientos (daño de computador y/o eliminación de cuenta de
        alojamiento de datos) se podrán restaurar todos los documentos
        creados a la fecha, siempre y cuando se realice adecuadamente el envío
        del consentimiento al paciente.`
      },
    ]
  },
  {
    title: "Legalidad",
    color: "blue",
    layout: "regular",
    image: MedisignImg2,
    description: `Medisign ayuda a promover la política de Cero Papel,
    impulsado por la Presidencia de la República desde el año 2012, cuyo
    propósito es la gestión pública efectiva, eficiente y eficaz del papel.
    Medisign cumple con los lineamientos estipulados en el artículo 28 de la
    Ley 527 de 1999, en relación con la validez de la firma digital `,
    list: [
      {
        description: `La firma es única a la persona que la usa y está bajo su
        control` },
      {
        description: `Las firmas pueden de ser verificadas.`
      },
      {
        description: `Está ligada a la información o mensaje, de tal manera
        que si éstos son cambiados, la firma.`
      },
      {
        description: `Medisign está conforme a las reglamentaciones adoptadas por el
        Gobierno Nacional.` },
    ]
  }
]
