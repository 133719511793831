import React from "react"

import Layout from "../components/layout.jsx"
import SEO from "../components/seo"
import HeaderMedsys from "../components/header-medsys/header-medsys.jsx"
import ProductDescription from "../components/product-description/product-description.jsx"
import { descriptionMedsys } from "../components/product-description/products.jsx"

function getProductDescriptions(){
  const descriptions = [];
  for (const description of descriptionMedsys){
    descriptions.push(
      <ProductDescription items={description.items} color={description.color}
      layout={description.layout} title={description.title}
      description={description.description}
      img={description.image}
      />
    )
  }
  return descriptions;
}

const MedsysPage = () => (
  <Layout>
    <SEO title="Medsys" />
    <HeaderMedsys/>
    {getProductDescriptions()}
  </Layout>
)

export default MedsysPage
