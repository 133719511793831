import "./header-medsys.scss";
import React from "react"
import Logo from "../../images/header/logo-white.png"
import LogoTxt from "../../images/header/logo-title-white.png"

class HeaderMedsys extends React.Component{
  render (){
    return (
      <article className="headermedsys">
        <div className="headermedsys__wrap">
          <img className="headermedsys__logo" src={Logo} alt="Logo Medsys" />
          <img className="headermedsys__logo-txt" src={LogoTxt} alt="Logo Medsys" />
          <p className="headermedsys__description">
            Software de administración de historias clinicas, agendas y RIPS.
          </p>
        </div>
      </article>
    )
  }

}

export default HeaderMedsys 
