import "./product-description.scss";
import React from "react"

class ProductDescription extends React.Component{

  getClassName(name){
    let classname = "";
    if(this.props.layout === 'regular'){
      classname =`${name} ${name}--regular`;
    } else {  
      classname =`${name} ${name}--reverse`;
    }

    classname +=` ${name}--${this.props.color}`

    return classname;
  }

  getListElements(){
    let list = [];
    if(this.props.list){
      for(const item of this.props.list){
        list.push(
          <li className="productDescription__list-description">
              {item.description}
          </li>
        );
      }
    }
    return list;
  }

  getListItems(){
    let list = [];
    if(this.props.items){
      for(const item of this.props.items){
        list.push(
          <li className="productDescription__item">
            <h2 className="productDescription__item-title">
              {item.title}
            </h2>
            <p className="productDescription__item-description">
              {item.description}
            </p>
          </li>
        );
      }
    }
    return list;
  }

  render (){
    return (
      <article className="productDescription">
        <div className={this.getClassName("productDescription__wrap")}>
          <div className={this.getClassName("productDescription__content")}>
            <div className="productDescription__content-wrap">
              <h1 className="productDescription__title">
                {this.props.title}
              </h1>
              <p className="productDescription__description">
                {this.props.description}
              </p>
              <ul className="productDescription__items">
                {this.getListItems()}
              </ul>
              <ul className="productDescription__list">
                {this.getListElements()}
              </ul>
            </div>
          </div>
          <div className="productDescription__line"></div>
          <div className="productDescription__img-wrap">
            <img className="productDescription__img" src={this.props.img} 
              alt="Screenshot"/>
          </div>
        </div>
      </article>
    )
  }

}

export default ProductDescription 

